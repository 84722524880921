import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Box, Grid, Paper } from "@material-ui/core"
import {
  Business,
  Mail,
  Phone,
  PhoneIphone,
  Facebook,
  YouTube,
  Instagram,
  Pageview,
} from "@material-ui/icons"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const year = new Date().getFullYear()

  return (
    <Box pt={5} pb={3}>
      <Paper elevation={4}>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box px={3} pt={4}>
              <Grid container direction="row" justify="center">
                <Grid item>
                  <div className="footer-item-wrapper">
                    <Box mr={2}>
                      <Business fontSize="large" color="secondary" />
                    </Box>
                    <div>
                      <span className="dark">İkitelli VD. V.No:</span>{" "}
                      31198823454
                      <br />
                      <span className="dark">Mersis No:</span> 3119882345400001
                      <br />
                      <span className="dark">Ticaret Sicil No:</span> 102885-5
                    </div>
                  </div>
                  <div className="footer-item-wrapper">
                    <Box mr={2}>
                      <Mail fontSize="large" color="secondary" />
                    </Box>
                    <div>
                      <a
                        className="footer-link"
                        href="mailto:info@erezkaynak.com"
                        target="_top"
                      >
                        info@erezkaynak.com
                      </a>
                    </div>
                  </div>
                  <div className="footer-item-wrapper">
                    <Box mr={2}>
                      <Phone fontSize="large" color="secondary" />
                    </Box>
                    <div>
                      <a className="footer-link" href="tel://02125493620">
                        0212 549 36 20
                      </a>
                    </div>
                  </div>
                  <div className="footer-item-wrapper">
                    <Box mr={2}>
                      <PhoneIphone fontSize="large" color="secondary" />
                    </Box>
                    <div>
                      <a className="footer-link" href="tel://05352229557">
                        0535 222 95 57
                      </a>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box px={3} pt={3} textAlign="center">
              <Box mb={2}>
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  alt="Erez Kaynak"
                />
              </Box>
              <div className="footer-item-wrapper center">
                <div>
                  <span className="dark">
                    EreZ Kaynak - Uğur Erkin DEMİRKAYA
                    <br />
                  </span>
                  <span>
                    İkitelli Org.San.Bölgesi Dersan Koop San.Sit.
                    <br />
                    S6A Blok No:121 Başakşehir / İSTANBUL
                  </span>
                </div>
              </div>
              <div className="footer-item-wrapper center">
                <a
                  style={{ color: "#ff0000" }}
                  className="footer-social"
                  href="https://www.youtube.com/watch?v=rZpN0Uyqp_o"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <YouTube fontSize="large" />
                </a>
                <a
                  style={{ color: "#3b5998" }}
                  className="footer-social"
                  href="https://www.facebook.com/erezkaynak"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Facebook fontSize="large" />
                </a>
                <a
                  style={{ color: "#bc2a8d" }}
                  className="footer-social"
                  href="https://www.instagram.com/erezkaynak/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Instagram fontSize="large" />
                </a>
                <a
                  style={{ color: "#dd4b39" }}
                  className="footer-social"
                  href="https://www.google.com/search?rlz=1C1OKWM_trTR856TR856&amp;ei=XxRuXc6-FJLZmwXK8KH4Cg&amp;q=Erez+Kaynak+%C3%87elik+Has%C4%B1r+Tel+ve+Filtre+Elek+%C4%B0malat%C3%A7%C4%B1s%C4%B1&amp;oq=Erez+Kaynak+%C3%87elik+Has%C4%B1r+Tel+ve+Filtre+Elek+%C4%B0malat%C3%A7%C4%B1s%C4%B1&amp;gs_l=psy-ab.3..38.742.742..962...0.2..0.126.126.0j1......0....1..gws-wiz.......0i71.QdESX30aPi4&amp;ved=0ahUKEwiO6u7yjrTkAhWS7KYKHUp4CK8Q4dUDCAo&amp;uact=5"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Pageview fontSize="large" />
                </a>
              </div>
              <div className="footer-item-wrapper center">
                © Copyright 2013 - {year} Her Hakkı Saklıdır.
              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
export default Footer
