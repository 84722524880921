import React from "react"

import "./main.css"

import SiteNav from "./navbar"
import SideBar from "./sidenav"
import Footer from "./footer"
import { CssBaseline, Container } from "@material-ui/core"

const Layout = props => {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  return (
    <CssBaseline>
      <SiteNav menuBar={handleDrawerToggle} />
      <Container maxWidth="xl" disableGutters>
        <SideBar mOpen={mobileOpen} menuBar={handleDrawerToggle} />
        <div className="content scrollbar">
          {props.children}
          <Footer />
        </div>
      </Container>
    </CssBaseline>
  )
}
export default Layout
