import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core"
import {
  Menu,
  Image,
  Help,
  AccountBalance,
  PermContactCalendar,
  Home,
} from "@material-ui/icons"
function SiteImage() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 70) {
            src
          }
          resize(height: 50) {
            src
          }
        }
      }
    }
  `)
  const logo = data.file.childImageSharp

  return (
    <picture>
      <source media="(min-width:800px)" srcSet={logo.fixed.src} />
      <img srcSet={logo.resize.src} alt="erez kaynak logo" />
    </picture>
  )
}
const SiteNav = props => {
  return (
    <AppBar
      position="static"
      color="inherit"
      elevation={4}
      className="top-navbar"
    >
      <Container disableGutters maxWidth="xl">
        <Toolbar>
          <Link to="/">
            <Box mt={1}>
              <SiteImage />
            </Box>
          </Link>
          <span className="spacer-span "></span>
          <Hidden mdUp implementation="js">
            <IconButton
              color="inherit"
              aria-label="menüyü aç"
              edge="start"
              onClick={props.menuBar}
            >
              <Menu />
            </IconButton>
          </Hidden>
          <Hidden smDown implementation="css">
            <Link to="/">
              <Button startIcon={<Home color="secondary" />}>Ana Sayfa</Button>
            </Link>
            <Link to="/galeri">
              <Button startIcon={<Image color="secondary" />}>
                Ürün Resimleri
              </Button>
            </Link>
            <Link to="/hakkimizda">
              <Button startIcon={<Help color="secondary" />}>Hakkımızda</Button>
            </Link>
            <Link to="/banka-bilgileri">
              <Button startIcon={<AccountBalance color="secondary" />}>
                Banka Bilgileri
              </Button>
            </Link>
            <Link to="/iletisim">
              <Button startIcon={<PermContactCalendar color="secondary" />}>
                İletişim
              </Button>
            </Link>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default SiteNav
